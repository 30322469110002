<template>
    <v-container fluid>
        <v-row>

            <v-col md="3">
                <v-card
                    class="mx-auto secondary"
                    width="256"
                    tile>
                    <v-navigation-drawer permanent class="secondary">
                        <v-divider></v-divider>
                        <v-list class="secondary"
                            nav
                            dense>
                            <v-list-item-group v-model="item" color="primary">
                                <template v-for="(item, i) in items">
                                    

                                    <v-list-group
                                        v-if="item.children"
                                        :key="i"
                                        no-action>

                                        <template v-slot:activator>
                                            <v-list-item-icon>
                                                <v-icon v-text="item.icon"></v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="item.title"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>

                                        <v-list-item
                                            v-for="(child, i) in item.children"
                                            :to="child.route"
                                            :key="i">
                                            <v-list-item-content>
                                                <v-list-item-title v-text="child.title"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-group>

                                    <v-list-item
                                        v-else
                                        :to="item.route"
                                        :key="i">
                                        <v-list-item-icon>
                                            <v-icon v-text="item.icon"></v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.title"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                
                            </v-list-item-group>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-col>

            <v-col  md="9">
                <router-view></router-view>
            </v-col>

        </v-row>
        
        
        
    </v-container>
</template>
<script>
import { LOGIN, profileDashboard } from '../../routes';
//import Dashboard from '../../components/author/dashboard/Home';
export default {
    data: () => ({
      item: 0,
      items: [
        { title: 'Geral', icon: 'mdi-cogs', route: profileDashboard.index },
        { 
            title: 'Festival', icon: 'mdi-account-multiple',
            children: [
                { title: 'Pedido Autorização', route: profileDashboard.festival.authorizationRequest.index },
                { title: 'Meus Festivais', route: profileDashboard.festival.myFestivals.index },
                { title: 'Minhas Inscrições', route: profileDashboard.festival.mySubscriptions },
            ]
        },
      ],
    }),
    created() {
        if (this.$user.isGuest) {
            this.$router.push({path: LOGIN}); //TODO check auth with globar guard
            return;
        }
    },
    components: {
        //Dashboard,
    }
}
</script>